import { useState } from "react";
import {SampleProjectComponent} from "./SampleProjectComponent";

export function InvoiceGenerator() {
  const invoice01 = 'https://brucenguyenawsbucket.s3.amazonaws.com/invoice/invoice01.png';
  const invoice02 = 'https://brucenguyenawsbucket.s3.amazonaws.com/invoice/invoice02.png';
  const invoice04 = 'https://brucenguyenawsbucket.s3.amazonaws.com/invoice/invoice04.png';
  const invoice05 = 'https://brucenguyenawsbucket.s3.amazonaws.com/invoice/invoice05.png';
  const invoiceVideo = 'https://brucenguyenawsbucket.s3.us-east-1.amazonaws.com/assets/invoiceGeneratorDemo.mp4';
  const [video,] = useState(invoiceVideo)
  const [image, ] = useState(invoice01)
  const [images, ] = useState([invoice01,invoice02,invoice04, invoice05])

  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(open => !open)
  }

  const detail = (
      <>
        <button onClick={() => toggleOpen()}>
          <p className="font-bold text-sm text-customGreen">What I did in the project: (Click to see full details)</p>
        </button>
        {open &&
          <div className="grid gap-1 mt-2">
            <p className="font-bold text-sm">Server: I used EC2 instance of AWS to host my web app.</p>
            <p className="font-bold text-sm">Git: I used gitlab to organize my code.</p>
            <p className="font-bold text-sm">Frontend: I used React Typescript.</p>
            <p className="font-bold text-sm">Backend: I used C Sharp .NET Framework </p>
            <p className="text-sm">- Set up backend using .NET 8 and Entity Framework Core and Clean Architecture</p>
            <p className="text-sm">Followed RESTful API, Created data tables with Pagination API</p>
            <p className="text-sm">Set up Authentication process using session-based mechanism.</p>
            <p className="text-sm">
              Set up Database using PostgreSQL</p>
          </div>}
      </>
  )

  const TABLE_ROWS = [
    {
      name: "Overview",
      detail: "This is a web application that helps global merchants to create quick invoices",
    },
    {
      name: "Estimated Cost",
      detail: "1 month",
    },
    {
      name: "Programming Languages",
      detail: ".NET Backend, React Frontend ",
    },
    {
      name: "Database",
      detail: "MySQL - PostgreSQL",
    },
    {
      name: "Tools and IDE",
      detail: "Rider",
    },
    {
      name: "Some details",
      detail: detail,
    },
    {
      name: "Source Code",
      detail: "Github is solely available for recruiters",
    },
    {
      name: "Final Product",
      detail: <a href="https://quickinvoicegenerator.com" className="underline underline-offset-4 text-customGreen">https://quickinvoicegenerator.com</a>,
    },
  ];

  return (
      <>
        <SampleProjectComponent video={video} image={image} images={images} tableRows={TABLE_ROWS} />
      </>
  );
}
