import React, {useState} from "react";
import {
  Drawer,
  Card,
} from "@material-tailwind/react";
import classNames from "classnames";
import avatar01 from '../../assets/avatar01.jpg'
import {useNavigate} from 'react-router-dom';
import {useApp} from "../../context/AppProvider";

export function SideBar() {
  const app = useApp()
  const navigate = useNavigate()

  const closeDrawer = () => app.setShowSideBar(false);

  const [activeButton, setActiveButton] = useState('aboutMe')
  const baseClasses = 'text-start block hover:text-white text-gray-300 py-2 px-4 cursor-pointer font-sans text-xs underline-animation'
  const activeClasses = 'activeClasses';

  return (
      <>
        {app.showSideBar &&
          <div className="md:hidden lg:hidden top-0">
            <Drawer open={app.showSideBar} onClose={closeDrawer} className="bg-customGray" overlay={false}>
              <Card
                shadow={false}
                className="h-[calc(100vh-2rem)] w-full p-2 bg-customGray"
              >
                <div className="flex justify-center items-center">
                  <img alt="" src={avatar01} className="rounded-full h-1/2"/>
                </div>
                <ul className="flex flex-col">
                  <li className="flex-1 mr-2">
                    <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'aboutMe'})}
                       onClick={() => {
                         setActiveButton('aboutMe')
                         navigate('/')
                         window.location.replace("#aboutMe")
                         app.setShowSideBar(false)
                       }}>ABOUT ME</a>
                  </li>
                  <li className="flex-1 mr-2 mt-2">
                    <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'workExperience'})}
                       onClick={() => {
                         setActiveButton('workExperience')
                         navigate('/')
                         window.location.replace("#workExperience")
                         app.setShowSideBar(false)
                       }}>MY WORK EXPERIENCE</a>
                  </li>
                  <li className="flex-1 mr-2 mt-2">
                    <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'projects'})}
                       onClick={() => {
                         setActiveButton('projects')
                         navigate('/')
                         window.location.replace("#projects")
                         app.setShowSideBar(false)
                       }}>RECENT PROJECTS</a>
                  </li>
                  <li className="flex-1 mr-2 mt-2">
                    <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'contact'})}
                       onClick={() => {
                         setActiveButton('contact')
                         navigate('/')
                         window.location.replace("#contact")
                         app.setShowSideBar(false)
                       }}>MY CONTACT</a>
                  </li>
                </ul>
              </Card>
            </Drawer>
          </div>
        }
      </>
  );
}