import React, {useCallback, useState} from 'react';

interface IMediaProps {
  image: string,
  images: string[],
  video?: string
}

const Media = (props: IMediaProps) => {
  const [currentMedia , setCurrentMedia] = useState<string>(props.video || props.image)
  const [imagesSelected, setImagesSelected] = useState<string[]>(props.images);
  const [image, setImage] = useState<string>(props.image);
  const videoThumbnail = 'https://brucenguyenawsbucket.s3.us-east-1.amazonaws.com/assets/videoThumbnail.png'

  const ShowMainMedia = useCallback(() => {
    if(currentMedia?.includes('.mp4')) {
      return (
          <div className="flex justify-center">
            <video className="w-full" controls>
              <source src={currentMedia} type="video/mp4"/>
              Your browser does not support the video tag
            </video>
          </div>
      )
    } else if(currentMedia) {
      return (
          <div className="flex justify-center">
            <img
            src={currentMedia}
            className="w-full h-auto"
            alt="Selected media"
            />
          </div>
      )
    } else {
      return null;
    }
  },[currentMedia, image])

  const hoverImageProduct = useCallback((hoveredMedia: string | undefined) => {
    if(hoveredMedia) {
      setCurrentMedia(hoveredMedia)
    }
  }, []);

  function ShowImage(index: number, imageShowed: string): React.ReactElement {
    return (
      <div className="rounded h-20 relative mr-4" key={index}>
        <img
          src={imageShowed}
          className={`h-full cursor-pointer w-full'
						${imageShowed === currentMedia
            ? 'border-red-700 border-solid border-2'
            : 'border-slate-900 border-solid border'
          }`}
          alt=""
          key={index}
          onClick={() => hoverImageProduct(imageShowed)}
        />
      </div>
    );
  }

  const ShowingImages = (
    <>
      {ShowMainMedia()}
      <div className="flex w-full overflow-auto py-4 whitespace-nowrap">
        {imagesSelected.map((image, index) => ShowImage(index, image))}
        {props.video && (
            <div className="rounded h-20 relative mr-4" key="video-thumbnail">
              <img
                  src={videoThumbnail}
                  className="h-full cursor-pointer w-full"
                  alt="video"
                  onClick={() => hoverImageProduct(props.video)}
                />
            </div>
        )}
      </div>
    </>
  );

  return (
    <>
      {ShowingImages}
    </>
  );
};

export default Media;
