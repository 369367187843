import { Card, Typography } from "@material-tailwind/react";
import Media from "../../../components/Media";
import { useState } from "react";

interface TableRow {
  name: string,
  detail: string | JSX.Element
}

interface IProjectProps {
  image?: string,
  images?: string[],
  tableRows: TableRow[],
  video?: string
}

export function SampleProjectComponent(props: IProjectProps) {
  const [image, ] = useState(props.image)
  const [images, ] = useState(props.images)
  const [video,] = useState(props.video)
  const TABLE_ROWS = props.tableRows

  return (
      <>
        <Card className="h-full w-full overflow-scroll bg-[#232D3F]">
          <table className="w-full table-auto text-left">
            <thead>
            <tr>
              <th className="border-b border-blue-gray-100 bg-[#005B41] p-4 col-span-1 text-white">Overview</th>
              <th className="border-b border-blue-gray-100 bg-[#005B41] p-4 col-span-3 text-white">Detail</th>
            </tr>
            </thead>
            <tbody className="bg-[#232D3F]">
            {TABLE_ROWS.map(({ name, detail }) => (
                <tr key={name} >
                  <td className="p-4 col-span-1">
                    <Typography variant="small" className="font-normal text-gray-300">
                      {name}
                    </Typography>
                  </td>
                  <td className="p-4 col-span-3">
                    <Typography variant="small" className="font-normal text-gray-300">
                      {detail}
                    </Typography>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>

          {image !== undefined && images !== undefined &&
            <div>
            <p className="text-white my-2 mx-3 font-bold">Some images : </p>
            <div className="px-3">
            <Media image={image} images={images} video={video}/>
            </div>
            </div>
          }
        </Card>
      </>
  );
}
